import React from "react";
import { Link } from "react-router-dom";
import { CSSObject } from "@emotion/react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { BlogPost } from "@Types/services/post";
import { Card, CardContent, CardHeader } from "../../emotion/components/Card";
import EllipsedText from "../../emotion/components/EllipsedContent";
import Sticker from "../../emotion/components/Sticker";
import { BodySm, Button, Column, Row } from "@Components";
import {
  colorPrimary,
  displayFlex,
  fontSizeMd,
  fontSizeXl,
  fontWeightMedium,
  lineHeightLg,
  lineHeightXl,
  marginBottomLg,
  marginBottomSm,
  marginMd,
  marginTopSm,
  paddingLeftZero,
} from "@Styles";
import colors from "@Variables/colors";
import { calculateReadingTime } from "../../emotion/helpers/calculateReadingTime";
import { formatDate } from "../../emotion/helpers/formatDate";
import { breakpointDown, breakpointUp } from "@Variables/breakpoints";

const cardTitleStyle: CSSObject[] = [
  colorPrimary("petition"),
  displayFlex,
  fontWeightMedium,
  lineHeightLg,
  {
    alignItems: "center",
    height: "2.5rem",
    [breakpointDown["sm"]]: [fontSizeXl, marginTopSm, marginBottomLg],
    [breakpointUp["md"]]: [marginBottomLg, marginTopSm, fontSizeXl],
  },
];

const BlogPostCard = (blogPost: BlogPost) => {
  const { i18n, t } = useTranslation();

  return (
    <Card {...blogPost}>
      <CardHeader
        alt={blogPost.name}
        attachment={blogPost.featuredImage}
        href={`/blog/${blogPost.name}`}
        size="lg"
        css={{ borderRadius: "0.5rem" }}
      ></CardHeader>
      <CardContent>
        <Column
          span={{ default: 1 }}
          css={[marginBottomSm, paddingLeftZero]}
        >
          {blogPost?.categories?.[0] && (
            <Sticker
              label={blogPost?.categories[0]?.name}
              style="default"
              variant="secondary"
            />
          )}
        </Column>
        <div css={cardTitleStyle}>
          <EllipsedText
            text={blogPost.title}
            lineClamp={{ default: 2, md: 2, xs: 2 }}
          />
        </div>
        <Row>
          {blogPost?.publishedAt && (
            <Column span={{ default: 6 }}>
              <BodySm css={{ color: colors.gray5 }}>
                {formatDate(blogPost?.publishedAt, (i18n.language = "de"))}
              </BodySm>
            </Column>
          )}
          <Column css={{ textAlign: "right" }}>
            <BodySm css={{ color: colors.gray5 }}>
              Lesezeit: {parse(calculateReadingTime(blogPost.content))}
            </BodySm>
          </Column>
          <BodySm css={[fontSizeMd, marginMd, lineHeightXl]}>
            <EllipsedText
              text={blogPost?.summary}
              lineClamp={{ default: 2 }}
            />
          </BodySm>
        </Row>
        <Button
          css={{ marginTop: "auto" }}
          as={Link}
          fullWidth={{ default: true }}
          to={`/blog/${blogPost.name}`}
          label={t("blogPost.cardButtonLabel")}
          variant="outline"
        />
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
