export const calculateReadingTime = (content: string) => {
  content = content.replace(/<[^>]*>/g, "");

  const wordsPerMinute = 200; // Average reading speed

  const words = content.trim().split(/\s+/).length; // Count words

  const minutes = Math.ceil(words / wordsPerMinute); // Calculate reading time in minutes
  return `${minutes} min${minutes > 1 ? "s" : ""}`;
};
