import { CSSObject } from "@emotion/react";
import { faCookieBite } from "@fortawesome/pro-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  bgWhite,
  elevation3,
  marginBottomLg,
  marginBottomMd,
  marginBottomMdZero,
  marginTopLg,
  paddingXXl,
  paddingYLg,
} from "@Styles";
import { useCookieOptions } from "@Utils/useCookies";
import { breakpointDown, zIndexes } from "@Variables";
import themes from "@Variables/themes";
import Button from "./Button";
import ButtonContainer from "./ButtonContainer";
import Column from "./Column";
import { Container } from "./Container";
import Headline from "./Headline";
import Row from "./Row";
import { Link as LinkComponent } from "./typography";

type CookieBannerProps = {
  /** Whether to display the cookie banner or not. */
  display: boolean;
};

/**
 * Displays a cookie consent banner.
 */
const CookieBanner = ({ display }: CookieBannerProps) => {
  const { t } = useTranslation();
  const {
    setAllConsents,
    setEssentialConsent,
    // toggleCookieModal
  } = useCookieOptions();

  const containerStyle: CSSObject = {
    alignItems: "flex-end",
    display: "flex",
    overflow: "auto",
    pointerEvents: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: zIndexes.modal,
  };

  const bannerContainerStyle: CSSObject = {
    maxHeight: "100%",
  };

  const bannerStyle: CSSObject[] = [
    bgWhite,
    elevation3,
    marginBottomLg,
    marginTopLg,
    paddingXXl,
    paddingYLg,
    {
      maxHeight: "100%",
      overflow: "auto",
      pointerEvents: "all",
    },
  ];

  return display ? (
    <div css={containerStyle}>
      <Container
        css={bannerContainerStyle}
        size="lg"
      >
        <Row>
          <Column
            span={{ default: 12, md: 10, lg: 8 }}
            offset={{ default: 0, md: 1, lg: 2 }}
          >
            <div css={bannerStyle}>
              <Row>
                <Column
                  css={marginBottomMd}
                  span={{ default: 12 }}
                >
                  <Headline
                    icon={faCookieBite}
                    iconColor={themes.petition.colors.primary}
                    title={t("components.cookieBanner.title")}
                    titleColor={themes.initiative.colors.primary}
                  />
                </Column>
                <Column
                  css={[
                    marginBottomLg,
                    marginBottomMdZero,
                    {
                      [breakpointDown["sm"]]: {
                        maxHeight: "12rem",
                        overflowY: "auto",
                        WebkitOverflowScrolling: "touch", // Enables smooth scrolling on iOS
                      },
                    },
                  ]}
                  span={{ default: 12, md: 8 }}
                >
                  <p>
                    <Trans i18nKey="components.cookieBanner.content">
                      {" "}
                      <LinkComponent
                        as={Link}
                        to="/datenschutz"
                      />
                      <LinkComponent to="/impressum" />
                    </Trans>
                  </p>
                </Column>
                <Column span={{ default: 12, md: 4 }}>
                  <ButtonContainer
                    align={{ default: "justify" }}
                    direction={{ default: "vertical" }}
                  >
                    <Button
                      label={t("components.cookieBanner.accept.all")}
                      onClick={setAllConsents}
                      variant="secondary"
                    />

                    <Button
                      label={t("components.cookieBanner.accept.essential")}
                      onClick={setEssentialConsent}
                      variant="primary"
                    />
                  </ButtonContainer>
                </Column>
              </Row>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default CookieBanner;
