import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import api from "@Utils/api";
import parse from "html-react-parser";
import { CSSObject } from "@emotion/react";
import { useTranslation } from "react-i18next";
import {
  faAngleRight,
  faGalleryThumbnails,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BlogPostCard from "./BlogPostCard";
import { BlogPost as BlogPostType } from "@Types/services/post";
import { calculateReadingTime } from "../../emotion/helpers/calculateReadingTime";
import colors from "@Variables/colors";
import Error from "../Error";
import { formatDate } from "../../emotion/helpers/formatDate";
import { sanitizeHtmlAndUpdateImages } from "../../emotion/helpers/sanitizeHtmlAndUpdateImages";
import { breakpointDown, breakpointUp } from "@Variables/breakpoints";
import ProgressBar from "./ProgressBar";

import {
  AttachmentImage,
  Body,
  BodySm,
  Button,
  CardColumn,
  Column,
  Container,
  Heading,
  Row,
  SocialMediaShare,
} from "@Components";

import {
  displayFlex,
  displayNone,
  fontSize4xl,
  fontSize5xl,
  fontSize6xl,
  fontSizeSm,
  fontSizeSmLg,
  gapMd,
  marginBottom2xl,
  marginBottomLg,
  marginBottomSm,
  marginLeft2xl,
  marginLeftZero,
  marginTop2xl,
  marginTopLg,
  marginYLg,
  paddingBottomLg,
  paddingBottomSm,
  paddingTopSm,
  paddingTopXl,
  paddingXZero,
  paddingYLg,
  paddingYMd,
} from "@Styles";

import Sticker from "../../emotion/components/Sticker";

const buttonStyle: CSSObject[] = [
  displayNone,
  marginTopLg,
  marginBottom2xl,
  {
    [breakpointUp["xs"]]: [marginLeftZero, paddingXZero],
    [breakpointUp["md"]]: [marginLeft2xl],
  },
];

const categoryStyle: CSSObject[] = [
  paddingTopXl,
  paddingBottomSm,
  { display: "flex", alignItems: "center", gap: "0.3rem" },
];

const headingSummaryStyle: CSSObject[] = [paddingTopSm, fontSizeSmLg];

const headingTitleStyle: CSSObject[] = [
  marginBottomSm,
  {
    [breakpointUp["xs"]]: [fontSize6xl],
    [breakpointUp["md"]]: [fontSize6xl],
  },
];

const imgStyle: CSSObject = {
  heigth: "100%",
  maxWidth: "100%",
};

const moreBlogsStyleContainer: CSSObject[] = [
  displayFlex,
  {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
];

const moreBlogsStyle: CSSObject[] = [
  marginTop2xl,
  marginBottomLg,
  {
    [breakpointDown["xs"]]: [fontSize5xl],
    [breakpointUp["md"]]: { fontSize: "3.815rem" },
  },
];

const socialMediaShareStyle: CSSObject[] = [
  displayFlex,
  gapMd,
  {
    alignItems: "center",
    flexDirection: "column",
  },
];

const socialMediaTitleStyle: CSSObject[] = [
  {
    [breakpointDown["sm"]]: [fontSize4xl],
    [breakpointUp["md"]]: [
      marginLeftZero,
      { fontSize: "3.05rem", width: "40%" },
    ],
    width: "50%",
  },
];

const BlogPost: React.FC = () => {
  const [blogPost, setBlogPost] = useState<BlogPostType>();
  const [morePosts, setMorePosts] = useState<BlogPostType[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isFirstCallComplete, setIsFirstCallComplete] = useState(false);
  const { _id: name } = useParams();

  const { i18n, t } = useTranslation();

  // fetch a single blog post
  const loadBlogPost = async (name: string) => {
    if (!name) {
      return setHasError(true);
    }
    try {
      const { data } = await api.getArticle(name);
      setBlogPost(data);
      setIsFirstCallComplete(true);
    } catch (error) {
      console.error(error);
    }
  };

  // fetch more blog posts
  const loadMoreBlogPosts = async () => {
    if (isFirstCallComplete && blogPost) {
      try {
        const { data } = await api.getArticles({
          limit: 4,
        });
        // Check if data is an array before filtering
        if (data && Array.isArray(data.data)) {
          // Remove the current blog post from the list of more posts,
          // to avoid showing it in the same place as the current post.
          const filteredPosts = data.data.filter(
            (post: any) => post.id !== blogPost.id,
          );
          setMorePosts(filteredPosts.slice(0, 3));
        } else {
          console.error("Expected an array but got:");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (name) {
      loadBlogPost(name);
    }
  }, [name]);

  useEffect(() => {
    loadMoreBlogPosts();
  }, [isFirstCallComplete, blogPost]);

  if (hasError) {
    return <Error />;
  }

  return (
    <>
      <ProgressBar />
      <Container size="md">
        {/* header breadcrumb*/}
        <div css={categoryStyle}>
          <Sticker
            label="Newsroom"
            style="outline"
            variant="secondary"
          />
          <FontAwesomeIcon
            aria-hidden={false}
            css={{ color: colors.mauve }}
            fixedWidth
            icon={faAngleRight}
          />
          {blogPost?.categories?.[0] && (
            <Sticker
              label={blogPost?.categories[0]?.name}
              style="default"
              variant="secondary"
            />
          )}
        </div>
        <Row>
          <Column span={{ default: 12, lg: 12 }}>
            <Heading
              scale={2}
              variant="display"
              color="primary"
              css={headingTitleStyle}
            >
              {blogPost?.title}
            </Heading>
            <Heading
              color="label"
              css={headingSummaryStyle}
              scale={5}
            >
              {blogPost?.summary}
            </Heading>
            <BodySm
              css={[
                paddingYMd,
                paddingBottomLg,
                fontSizeSm,
                { display: "flex", alignItems: "center", gap: "0.5rem" },
              ]}
            >
              {blogPost?.publishedAt && (
                <>
                  <span>
                    {formatDate(blogPost?.publishedAt, i18n.language, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                  <span>|</span>
                </>
              )}
              {blogPost?.content && (
                <span>
                  Lesezeit: {parse(calculateReadingTime(blogPost.content))}
                </span>
              )}
            </BodySm>
            <div css={[marginYLg]}>
              <AttachmentImage
                imageCss={[{ borderRadius: "0.5rem" }]}
                alt={blogPost?.title}
                attachment={blogPost?.featuredImage}
                size="xl"
              />
            </div>
          </Column>
          <Column>
            {/* Main content */}
            {blogPost?.content && (
              <div css={[paddingYLg]}>
                <Body
                  css={{
                    "& img.img": imgStyle,
                    lineHeight: "1.6rem",
                  }}
                >
                  {parse(sanitizeHtmlAndUpdateImages(blogPost.content))}
                </Body>
              </div>
            )}
            <div
              css={[
                paddingBottomLg,
                { borderBottom: `1px solid ${colors.gray3}` },
              ]}
            >
              <div css={socialMediaShareStyle}>
                <Heading
                  variant="display"
                  scale={4}
                  css={socialMediaTitleStyle}
                  color="secondary"
                >
                  {t("blogPost.share.title")}
                </Heading>
                {blogPost?.title && (
                  <SocialMediaShare
                    css={[marginBottomLg]}
                    signableTitle={blogPost.title}
                    signableType="petition"
                    url={window.location.href}
                    context="blogPost"
                    variant="secondary"
                    background="dark"
                    size={"sm"}
                    socialMediaList={[
                      "facebook",
                      "twitter",
                      "email",
                      "whatsapp",
                      "link",
                    ]}
                    includeLabel={false}
                    transparent
                  />
                )}
              </div>
            </div>
          </Column>
        </Row>
      </Container>
      <Container size="lg">
        <div css={moreBlogsStyleContainer}>
          <div>
            <Heading
              variant="display"
              scale={3}
              color="primary"
              css={moreBlogsStyle}
            >
              Weitere Blog Posts
            </Heading>
          </div>
        </div>
        <Row>
          {morePosts?.map((blogPost) => {
            return (
              <CardColumn
                css={[marginBottomLg]}
                key={blogPost.id}
                span={{ default: 12, md: 6, lg: 4 }}
              >
                <BlogPostCard {...blogPost} />
              </CardColumn>
            );
          })}
        </Row>
        <Row>
          <Column
            span={{ default: 12, md: 3, xs: 12 }}
            offset={{ default: 0, md: 4, xs: 0 }}
          >
            <Button
              css={buttonStyle}
              as={Link}
              icon={faGalleryThumbnails}
              fullWidth={{ default: true }}
              to={"/blogs"}
              label={t("blogPost.buttonLabel")}
              variant="secondary"
            />
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default BlogPost;
