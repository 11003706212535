import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CardColumn, Container, Heading, Row, SignableCard } from "@Components";
import { marginBottomXl, marginTopXl, paddingXMd } from "@Styles/spacers";

import { Signable } from "@Types/services/post";
import api from "@Utils/api";

const More = ({ signable }: { signable: Signable }) => {
  const { t } = useTranslation();
  const [signables, setSignables] = useState<Signable[]>([]);

  const loadSignables = async () => {
    if (!signable.tag || !signable.tag._id) return;

    try {
      const { data } = await api.getSignables({
        exclude: signable._id,
        limit: 3,
        tag: (signable.tag && "_id" in signable.tag && signable.tag._id) || "",
        type: signable.type,
        success: "false",
        sort: "signatureCount",
      });
      setSignables(
        data.map((signable) => ({
          ...signable,
          initiators:
            signable.initiators &&
            Array.isArray(signable.initiators) &&
            signable.initiators.length
              ? signable.initiators
              : signable._initiators,
        })),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadSignables();
  }, [signable]);

  return signable.tag && signables.length > 0 ? (
    <Container
      css={[marginTopXl, paddingXMd]}
      size="lg"
    >
      <Heading
        scale={3}
        aria-level={2}
        role="none"
        color="label"
        css={marginBottomXl}
      >
        {t("pages.signable.more.title")}
      </Heading>

      <Row>
        {signables.map((signable) => (
          <CardColumn
            key={`more-signable-${signable._id}`}
            span={{ default: 12, md: 4 }}
          >
            <SignableCard
              mode="default"
              signable={signable}
            />
          </CardColumn>
        ))}
      </Row>
    </Container>
  ) : null;
};

export default More;
