import React, { useEffect, useRef, useState } from "react";
import { CSSObject } from "@emotion/react";

import colors from "@Variables/colors";

const progressBarContainerStyle: CSSObject = {
  backgroundColor: `${colors.gray1}`,
  display: "none",
  height: "0.5rem",
  left: "0",
  position: "fixed",
  top: "30",
  width: "100%",
  zIndex: "10",
};

const progressBarStyle: CSSObject = {
  backgroundColor: `${colors.orange}`,
  height: "100%",
  transition: "width 0.1s ease-out",
};

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Scroll position relative to the entire document
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight; // Total height of the document
      const scrollPercent = (scrollTop / docHeight) * 100;

      setProgress(scrollPercent);

      if (progressBarRef.current) {
        progressBarRef.current.style.display =
          scrollPercent > 0 ? "block" : "none";
      }
    };

    // Add the event listener for the scroll event
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      css={progressBarContainerStyle}
      ref={progressBarRef}
    >
      <div css={[progressBarStyle, { width: `${progress}%` }]}></div>
    </div>
  );
};

export default ProgressBar;
