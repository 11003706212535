import DOMPurify from "dompurify";
// this function remove inherited width and height attributes from image tags and replace them with set values
export const sanitizeHtmlAndUpdateImages = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const imgTags = doc.querySelectorAll("img");
  const figures = doc.querySelectorAll("figure");
  const pTags = doc.querySelectorAll("p");

  //remove inherited margin attributes
  figures.forEach((figure) => {
    figure.style.margin = "0";
  });

  pTags.forEach((p) => {
    p.style.lineHeight = "1.6";
  });

  imgTags.forEach((img) => {
    img.removeAttribute("width");
    img.removeAttribute("height");
    img.classList.add("img");
  });

  //increase line height attributes for all p tags
  pTags.forEach((p) => {
    p.style.lineHeight = "1.6";
  });

  // Sanitize the modified HTML
  return DOMPurify.sanitize(doc.body.innerHTML);
};
