import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useCookies } from "react-cookie";

enum InnnitCookies {
  COOKIE_CONSENT = "cookieConsent",
  BANNER_CLOSED = "bannerClosed",
  MTM_CAMPAIGN = "mtmCampaign",
}

export const useCookieOptions = () => {
  const [cookies, setCookie] = useCookies([
    InnnitCookies.COOKIE_CONSENT,
    InnnitCookies.BANNER_CLOSED,
    InnnitCookies.MTM_CAMPAIGN,
  ]);
  const { pushInstruction } = useMatomo();

  const cookieCategories = [
    "essential",
    "statistics",
    "banner",
    "mtm_campaign",
    // "socialMedia",
    // "thirdParty",
  ];

  const setConsents = (consents: string[]) => {
    setCookie(InnnitCookies.COOKIE_CONSENT, consents, {
      path: "/",
      secure: true,
    });

    if (consents.includes("statistics")) {
      pushInstruction("setConsentGiven");
      pushInstruction("setCookieConsentGiven");
    } else {
      pushInstruction("forgetConsentGiven");
      pushInstruction("forgetCookieConsentGiven");
    }
  };

  const setAllConsents = () => {
    setConsents(cookieCategories);
  };

  const setEssentialConsent = () => {
    setConsents(["essential"]);
  };

  const setBannerConsent = () => {
    // Set banner cookie with 12-hour expiration
    setCookie(InnnitCookies.BANNER_CLOSED, "true", {
      path: "/",
      secure: true,
      expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
    });
  };

  const resetCookies = () => {
    // Remove cookie consent by setting an expired cookie
    setCookie(InnnitCookies.COOKIE_CONSENT, "", {
      path: "/",
      expires: new Date(0),
    });
    setCookie(InnnitCookies.BANNER_CLOSED, "", {
      path: "/",
      expires: new Date(0),
    }); // Clear banner cookie
    setCookie(InnnitCookies.MTM_CAMPAIGN, "", {
      path: "/",
      expires: new Date(0),
    }); // Clear campaign cookie

    // Forget Matomo consent
    pushInstruction("forgetConsentGiven");
    pushInstruction("forgetCookieConsentGiven");
  };

  const setMtmCampaign = (matomoCampaign: string): void => {
    setCookie(InnnitCookies.MTM_CAMPAIGN, matomoCampaign, {
      path: "/",
      expires: new Date(Date.now() + 12 * 60 * 60 * 1000), // 12 hours
    });
  };

  return {
    cookieCategories,
    cookieConsent: cookies.cookieConsent,
    bannerClosed: cookies.bannerClosed,
    mtmCampaign: cookies.mtmCampaign,
    setAllConsents,
    setConsents,
    setEssentialConsent,
    setBannerConsent,
    setMtmCampaign,
    resetCookies,
  };
};
