// import { useContext, useEffect } from "react";

// import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Visible } from "@Atoms";
import {
  BodyLg,
  Button,
  ButtonContainer,
  Column,
  Container,
  Heading,
  Illustration,
  Placeholder,
  Row,
  SocialMediaShare,
} from "@Components";

import {
  illustrationStyles,
  rowStyles,
} from "@Appearances/pages/stylePSFDonation";
// import { UserContext } from "@Contexts/UserContext";
import { useTheme } from "@emotion/react";
import {
  faChevronsLeft,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-regular-svg-icons";
import { marginBottomLg, marginBottomMdLg, marginBottomXl } from "@Styles";
import { PSFSharedStepsProps } from "@Types/components/psf";

type ISharePageProps = Omit<PSFSharedStepsProps, "signer">;

const SharePage = ({ loaded, signable }: ISharePageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tone } = useTheme();
  // const { trackEvent } = useMatomo();
  // const { isAuth } = useContext(UserContext);

  // useEffect(() => {
  //   trackEvent({
  //     category: "signable",
  //     action: "shareLoaded",
  //     customDimensions: [
  //       {
  //         id: 1, // Signable ID
  //         value: signable._id,
  //       },
  //       {
  //         id: 2, // Signable model
  //         value: signable.type,
  //       },
  //       {
  //         id: 4, // Logged in
  //         value: isAuth ? "true" : "false",
  //       },
  //     ],
  //   });
  // }, [isAuth, signable, trackEvent]);

  return (
    <Container
      size="lg"
      css={marginBottomMdLg}
    >
      <Row css={rowStyles}>
        <Column
          span={{ default: 12, md: 5 }}
          offset={{ default: 0, md: 1 }}
        >
          <Heading
            variant="display"
            scale={2}
            css={[marginBottomLg]}
            color="primary"
          >
            {loaded ? t("pages.psf.share.title") : <Placeholder lines={2} />}
          </Heading>

          <BodyLg>
            {loaded ? t("pages.psf.share.text") : <Placeholder lines={3} />}
          </BodyLg>

          <Visible when={loaded}>
            <div css={marginBottomXl}>
              <SocialMediaShare
                context="psf"
                signableTitle={signable.title}
                signableType={signable.type}
                url={`https://innn.it/${signable.name}`}
                size="md"
                transparent
                includeLabel
              />
            </div>
          </Visible>

          <ButtonContainer
            align={{ default: "justify" }}
            direction={{ default: "vertical" }}
          >
            <Button
              as={Link}
              to={tone === "initiative" ? "/initiatives" : "/"}
              icon={faRectangleVerticalHistory}
              label={t(`pages.psf.share.buttons.other.${tone}`)}
              loading={!loaded}
              variant="primary"
            />

            <Button
              icon={faChevronsLeft}
              label={t(`pages.psf.share.buttons.back.${tone}`)}
              onClick={() => navigate(`/${signable.name}/`)}
              loading={!loaded}
              variant="outline"
            />
          </ButtonContainer>
        </Column>

        <Column
          span={{ default: 12, md: 4 }}
          offset={{ default: 0, md: 1 }}
        >
          <div css={illustrationStyles}>
            <Visible when={loaded}>
              <Illustration
                name="share"
                size="lg"
              />
            </Visible>
          </div>
        </Column>
      </Row>
    </Container>
  );
};

export default SharePage;
